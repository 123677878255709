import * as yup from "yup";
import { number, string } from "yup";

import { PlantGuest } from "../../../../../api/types/plants";
import {
  CafsValueRemarksValidation,
  emptyToNullTransform,
} from "../../../../../consts/validation";
import { CafsForm } from "../../../../../types/cafs-form";

export const guestCreateSchema = yup.object<CafsForm<keyof PlantGuest>>({
  Name: yup.object({
    Base: string().transform(emptyToNullTransform).required(),
  }),
  Category: yup.object({
    Base: string().transform(emptyToNullTransform).required(),
  }),
  PressureMaxScale: yup.object({
    Base: number().nullable().transform(emptyToNullTransform),
  }),
  RpMonth: yup.object({
    Base: number().nullable().transform(emptyToNullTransform),
  }),
  InitialMonth: yup.object({
    Base: number().nullable().transform(emptyToNullTransform),
  }),
  DurationMonths: yup.object({
    Base: string().nullable().transform(emptyToNullTransform),
  }),
  RhythmYears: yup.object({
    Base: string().nullable().transform(emptyToNullTransform),
  }),
  ControlToBiodiversity: yup.object({
    Base: number().nullable().transform(emptyToNullTransform),
  }),
  OptSOMtpHa: yup.object({
    Base: number().nullable().transform(emptyToNullTransform),
  }),
  GeneralRemarks: CafsValueRemarksValidation(true),
});
