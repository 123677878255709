import { VariableInfo } from "./map-string-to-variable-Info";

export function groupVariablesBySection(
  variableInfos: VariableInfo[],
): Map<string, string[]> {
  const sectionMap = new Map<string, string[]>();

  variableInfos.forEach(({ section, variable }) => {
    const variablesInSection = sectionMap.get(section) || [];
    sectionMap.set(section, [...variablesInSection, variable]);
  });

  return sectionMap;
}
