import { ReactNode } from "react";
import * as React from "react";
import { FieldValues, Path } from "react-hook-form";

import { Help } from "@mui/icons-material";
import { IconButton, TableCell, TableRow, Tooltip } from "@mui/material";
import DOMPurify from "dompurify";

import { CafsValue } from "../../../../api/types/cafs";
import { TooltipItem } from "../../../../api/types/tooltips";
import { Formula } from "../../../../components/formula/Formula";
import { TextWithEllipsis } from "../../../../components/TextWithEllipsis";
import {
  getBaseValue,
  getCalculatedValue,
  getCurrentValue,
  getParentValue,
} from "../../../../utils/variable-helper";
import { CafsSection } from "../../const/cafs-section";
import { ReferenceAssign } from "../ReferenceAssign";

export type InputRowProps<T extends Path<FieldValues>> = {
  variableName: T;
  variableValue: CafsValue;
  productCode?: string;
  guestName?: string | null;
  tooltip?: TooltipItem;
  sectionName: CafsSection;
  hidden?: ["Parent" | "New Base" | "Calculated" | "Current" | ""];
};

export function InfoRow<T extends Path<FieldValues>>({
  variableName,
  variableValue,
  tooltip,
  sectionName,
  productCode,
  guestName,
  hidden = [""],
}: InputRowProps<T>) {
  const sanitizedDescription = DOMPurify.sanitize(tooltip?.Description ?? "");
  const renderCalculated = !hidden.includes("Calculated");
  return (
    <TableRow
      sx={{ height: "85px" }}
      id={`${sectionName}_${variableName}`}
      key={`${variableName}`}
      data-testid={`VariableRow.${variableName}`}>
      {/* Name */}

      <TableCell>
        <span className="font-mono">{variableName as ReactNode}</span>
      </TableCell>

      {/* Tooltip */}

      <TableCell>
        {tooltip && tooltip.Description && (
          <Tooltip
            title={
              <span
                dangerouslySetInnerHTML={{ __html: sanitizedDescription }}
              />
            }
            data-testid={`${variableName}.tooltip`}>
            <IconButton className="hover:text-primary">
              <Help />
            </IconButton>
          </Tooltip>
        )}
      </TableCell>

      <TableCell>
        <TextWithEllipsis text={tooltip?.Unit ?? ""} limit={10} />
      </TableCell>

      {/* Parent */}

      <TableCell>
        <Formula value={getParentValue(variableValue)} />
      </TableCell>

      {/* Base */}

      <TableCell>
        <Formula value={getBaseValue(variableValue)} />
      </TableCell>

      {/* Calculated */}

      {renderCalculated && (
        <TableCell>
          <Formula value={getCalculatedValue(variableValue)} />
        </TableCell>
      )}

      {/* Current */}

      <TableCell>
        <div className="text-right">
          <Formula value={getCurrentValue(variableValue)} />
        </div>
      </TableCell>
      <TableCell>
        <ReferenceAssign
          fieldName={variableName}
          sectionName={sectionName}
          productCode={productCode}
          guestName={guestName}
        />
      </TableCell>
    </TableRow>
  );
}
