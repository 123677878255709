import { useContext } from "react";
import { FormattedMessage } from "react-intl";

import { PlantProduct } from "../../../api/types/plants";
import { ConfirmationModal } from "../../../components/ConfirmationModal";
import { Trash } from "../../../components/icons/trash";
import { useDeletePlantProductForm } from "../api/usePlantProductDelete";
import { CafsContext } from "../CafsContext";

export type DeleteProductModalProps = {
  open: boolean;
  onClose: () => void;
  product: PlantProduct;
};

export function DeleteProductModal({
  open,
  onClose,
  product,
}: DeleteProductModalProps) {
  const { cafs } = useContext(CafsContext);
  const { deleteProduct, isPending } = useDeletePlantProductForm({
    onSuccess: onClose,
  });

  return (
    <ConfirmationModal
      onConfirm={() => deleteProduct(product!.Id!)}
      onCancel={onClose}
      variant="error"
      okText="Delete"
      header="Delete plant product"
      disabled={isPending}
      icon={<Trash />}
      text={
        <p>
          <FormattedMessage
            id="DELETE_PLANT_PRODUCT_CONFIRMATION"
            defaultMessage="Are you sure you want to delete this {code} product for {plantName}? If the parent plant has a {code} product, this product will be replaced by the {code} product from the parent plant."
            values={{
              code: (
                <span className="font-semibold">{product?.Code?.Current}</span>
              ),
              plantName: (
                <span className="font-semibold">{cafs?.FullName}</span>
              ),
            }}
          />
        </p>
      }
      open={open}
    />
  );
}
