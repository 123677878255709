import { PlantDetailsForm } from "../../../types/plant-details-form";

export const defaultPlantFormValues = (data: PlantDetailsForm) => ({
  EnglishName: {
    Base: data.EnglishName?.Base ?? data.EnglishName?.Current,
  },
  FormerRecName: {
    Base: data.FormerRecName?.Base ?? data.FormerRecName?.Current,
  },
  Specification: {
    Base: data.Specification?.Base ?? data.Specification?.Current,
  },
  OrderName: {
    Base: data.OrderName?.Base ?? data.OrderName?.Current,
  },
  ScientificName: {
    Base: data.ScientificName?.Base ?? data.ScientificName?.Current,
  },
  GeneralRemarks: {
    Base: data.GeneralRemarks?.Base ?? data.GeneralRemarks?.Current,
  },
});
