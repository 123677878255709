import React, { useEffect, useState } from "react";

import { Clear } from "@mui/icons-material";
import { Button, TextField } from "@mui/material";

import { Search } from "./icons/Search";

export type SearchBarProps = {
  placeholder?: string;
  onChange: (value: string) => void;
  initialValue?: string;
  searchIcon?: boolean;
};

export function SearchBar({
  onChange,
  initialValue,
  placeholder = "Search...",
  searchIcon = false,
}: SearchBarProps) {
  const [value, setValue] = useState<string | undefined>("");

  const handleChange = (value: string) => {
    setValue(value);
    onChange(value);
  };

  useEffect(() => {
    setValue(initialValue);
  }, [initialValue]);

  return (
    <div className="flex flex-row gap-4 items-center">
      {searchIcon && <Search className="text-2xl text-primary" />}
      <TextField
        label={placeholder}
        value={value}
        sx={{ width: 1 }}
        size="small"
        onChange={e => handleChange(e.target.value)}></TextField>
      <Button
        variant="contained"
        disabled={!value}
        onClick={() => handleChange("")}>
        <Clear />
      </Button>
    </div>
  );
}
