import React from "react";
import { useIntl } from "react-intl";
import { useMatch } from "react-router-dom";

import { LoadingPlaceholder } from "../../components/LoadingPlaceholder";

import { useCafsDetailsUpdate } from "./api/useCafsDetailsUpdate";
import { useInputDetailsData } from "./api/useInputDetailsData";
import { useReferencesAssignedList } from "./api/useReferencesAssignedList";
import { InputDetails } from "./features/input-details/InputDetails";
import { InputTabs } from "./features/InputTabs";
import { PlantDetails } from "./features/plant-details/PlantDetails";
import { PlantTabs } from "./features/PlantTabs";
import { CafsContextProvider } from "./CafsContext";

export function CafsView() {
  const matchInput = useMatch("/dashboard/input/:datasetId/:id");

  const { data, isLoading } = useInputDetailsData();
  const { assignedReferencesList } = useReferencesAssignedList();
  const { updateCafs, apiErrors } = useCafsDetailsUpdate();

  const { formatMessage } = useIntl();

  return (
    <CafsContextProvider
      cafs={data ?? {}}
      assignedReferences={assignedReferencesList}>
      <div className="w-full">
        {isLoading ? (
          <LoadingPlaceholder
            text={formatMessage({
              id: "APP.LOADING_CAFS_DATA",
              defaultMessage: "Loading CAFS data....",
              description: "Message displayed when loading cafs data",
            })}
          />
        ) : (
          <>
            {matchInput ? (
              <>
                <InputDetails
                  data={data ?? {}}
                  onSubmit={updateCafs}
                  apiValidationErrors={apiErrors}
                />
                <InputTabs className="mt-8" />
              </>
            ) : (
              <>
                <PlantDetails
                  data={data ?? {}}
                  onSubmit={updateCafs}
                  apiValidationErrors={apiErrors}
                />
                <PlantTabs className="mt-8" />
              </>
            )}
          </>
        )}
      </div>
    </CafsContextProvider>
  );
}
