import { useEffect } from "react";
import { FieldValues, FormProvider, useForm } from "react-hook-form";

import { yupResolver } from "@hookform/resolvers/yup";
import { Alert } from "@mui/material";

import { PlantCosts } from "../../../../../api/types/plants";
import { useApiValidation } from "../../../../../hooks/useApiValidation";
import { useFetchData } from "../../../../../hooks/useFetchData";
import { CafsForm } from "../../../../../types/cafs-form";
import { SectionProps } from "../../../../../types/section-props";
import { useCostsData } from "../../../api/useCostsData";
import { useCostsUpdate } from "../../../api/useCostsUpdate";
import { CafsSection } from "../../../const/cafs-section";
import { useSectionSaveDisabled } from "../../../hooks/useSectionSaveDisabled";
import { mapInitialValues } from "../../../utils/map-initial-values";
import { renderCafsTableRow } from "../../../utils/render-cafs-table-row";
import { CafsTable } from "../../cafs-table/CafsTable";
import { SectionRemarks } from "../SectionRemarks";

import { costsSchema } from "./costs-schema";
import { useCostsVariables } from "./useCostsVariales";

export function CostsSection({ expanded }: SectionProps) {
  const variables = useCostsVariables();

  const { shouldFetchData } = useFetchData(expanded);

  const { costsData, isPending } = useCostsData(shouldFetchData);

  const { updateCosts, apiErrors } = useCostsUpdate({
    onSuccess: () => {},
  });

  const methods = useForm<FieldValues>({
    resolver: yupResolver(costsSchema),
    mode: "onChange",
  });

  const handleSubmit = (data: Partial<CafsForm<PlantCosts>>) => {
    updateCosts(data);
  };

  useEffect(() => {
    if (costsData) {
      methods.reset(mapInitialValues(costsData, variables));
    }
  }, [costsData]);

  useApiValidation(apiErrors, methods);

  const { disabled } = useSectionSaveDisabled(methods, isPending);

  return costsData && !isPending ? (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(handleSubmit)}>
        <SectionRemarks<PlantCosts> name="CostsRemarks" data={costsData} />
        <CafsTable<PlantCosts>
          section={CafsSection.PlantCost}
          rows={variables}
          render={renderCafsTableRow}
          data={costsData ?? {}}
          saveDisabled={disabled}
        />
      </form>
    </FormProvider>
  ) : (
    <div className="w-full">
      <Alert severity="info">Loading data</Alert>
    </div>
  );
}
