import { CafsValue } from "../../../../../api/types/cafs";
import { PlantBiomassAllocation } from "../../../../../api/types/plants";
import { CafsVariable } from "../../../../../types/cafs-variable";
import { CafsVariableType } from "../../../const/cafs-variable-type";

import { potentialMenaAnnualIncrementStem } from "./functions/potential-mena-annual-increment-stem";

export function useBiomassVariables(
  biomassAllocationData:
    | Partial<Record<keyof PlantBiomassAllocation, CafsValue>>
    | undefined,
): CafsVariable<PlantBiomassAllocation>[] {
  return [
    {
      name: "BiomassRemarks",
      type: CafsVariableType.Remarks,
    },
    {
      name: "PRootAlloc",
      type: CafsVariableType.Input,
    },
    {
      name: "PLeavesAlloc",
      type: CafsVariableType.Input,
    },
    {
      name: "P1Alloc",
      type: CafsVariableType.Input,
    },
    {
      name: "P2Alloc",
      type: CafsVariableType.Input,
    },
    {
      name: "P3Alloc",
      type: CafsVariableType.Input,
    },
    {
      name: "P4Alloc",
      type: CafsVariableType.Input,
    },
    {
      name: "BiomassPotProdDM",
      type: CafsVariableType.Input,
    },
    {
      name: "PotentialMeanAnnualIncrementStem",
      type: CafsVariableType.Calculated,
      dependOn: ["P3Alloc", "BiomassPotProdDM"],
      calculationFn: potentialMenaAnnualIncrementStem,
      data: biomassAllocationData,
    },
    {
      name: "PublicDatabase",
      type: CafsVariableType.Boolean,
    },
    {
      name: "ScientificSemi",
      type: CafsVariableType.Boolean,
    },
    {
      name: "Scientific",
      type: CafsVariableType.Boolean,
    },
    {
      name: "Qualitative",
      type: CafsVariableType.Boolean,
    },
    {
      name: "QuantitativeMeasured",
      type: CafsVariableType.Boolean,
    },
    {
      name: "QuantitativeProcessed",
      type: CafsVariableType.Boolean,
    },
    {
      name: "CalibratedBiomass",
      type: CafsVariableType.Boolean,
    },
    {
      name: "CalibratedHeightDBH",
      type: CafsVariableType.Boolean,
    },
    {
      name: "CalibratedTimeSeries",
      type: CafsVariableType.Boolean,
    },
    {
      name: "Validated",
      type: CafsVariableType.Boolean,
    },
  ];
}
