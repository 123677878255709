import { FormattedMessage } from "react-intl";

import { ModalSize, MuiModal } from "../../../components/mui/MuiModal";
import { usePlantProductCreate } from "../api/usePlantProductCreate";
import { ProductCreate } from "../components/cafs-sections/cafs-products/ProductCreate";

export type CreateProductModalProps = {
  open: boolean;
  onClose: () => void;
};

export function CreateProductModal({ open, onClose }: CreateProductModalProps) {
  const handleClose = () => {
    clearApiErrors();
    onClose();
  };

  const { createProduct, apiErrors, clearApiErrors, isPending } =
    usePlantProductCreate({
      onSuccess: handleClose,
    });

  return (
    <MuiModal
      open={open}
      onClose={handleClose}
      size={ModalSize.Big}
      header={
        <FormattedMessage
          id="MODAL.CREATE_PRODUCT_HEADER"
          defaultMessage="Create product"
          description="Create productt modal header"
        />
      }>
      <ProductCreate
        onSubmit={createProduct}
        customErrors={apiErrors}
        onCancel={handleClose}
        disabled={isPending}
      />
    </MuiModal>
  );
}
