import { useContext } from "react";
import { FormattedMessage, useIntl } from "react-intl";

import { Dataset } from "../../../api/types/datasets";
import { ConfirmationModal } from "../../../components/ConfirmationModal";
import { Trash } from "../../../components/icons/trash";
import { useDatasetUnassign } from "../api/useDatasetUnassign";
import { CafsContext } from "../CafsContext";

export type DeleteProductModalProps = {
  open: boolean;
  onClose: () => void;
  dataset: Dataset | undefined;
};

export function UnassignDatasetModal({
  open,
  onClose,
  dataset,
}: DeleteProductModalProps) {
  const { cafs } = useContext(CafsContext);
  const { formatMessage } = useIntl();
  const { unassignDataset } = useDatasetUnassign({ onSuccess: onClose });

  return (
    <ConfirmationModal
      onConfirm={() => unassignDataset(dataset?.Id!)}
      onCancel={onClose}
      variant="error"
      okText={formatMessage({
        id: "APP.UNASSIGN",
        defaultMessage: "Unassign",
        description: "Unassign generel text",
      })}
      header={
        <FormattedMessage
          id="MODAL.UNASSING_DATASET_HEADER"
          defaultMessage="Unassign dataset"
          description="Unassign dataset modal header"
        />
      }
      icon={<Trash />}
      text={
        <p>
          <FormattedMessage
            id="MODAL.UNASSING_DATASET_TEXT"
            defaultMessage="Are you sure you want to unassign {code} dataset from {plantName}?"
            values={{
              code: <span className="font-semibold">{dataset?.Name}</span>,
              plantName: (
                <span className="font-semibold">{cafs?.FullName}</span>
              ),
            }}
          />
        </p>
      }
      open={open}
    />
  );
}
