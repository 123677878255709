import React, { useState } from "react";
import { FormattedMessage } from "react-intl";

import { Button, TableCell, TableRow } from "@mui/material";

import { Edit } from "../../../components/icons/Edit";
import { MuiInput } from "../../../components/mui/MuiInput";
import { TooltipItem } from "../api/useFetchTooltips";

export type VariablesTableRowProps = {
  name: string;
  data: Omit<TooltipItem, "Name">;
  onEdit: (name: string) => void;
  onSave: (data: TooltipItem) => void;
  onCancel: () => void;
  editMode?: boolean;
  disabled?: boolean;
};

export function VariablesTableRow({
  data,
  name,
  onEdit,
  editMode,
  onCancel,
  disabled,
  onSave,
}: VariablesTableRowProps) {
  const [description, setDescription] = useState(data.Description);
  const [unit, setUnit] = useState(data.Unit);

  return (
    <TableRow>
      <TableCell sx={{ width: "30%", height: "75px" }}>{name}</TableCell>
      <TableCell sx={{ width: "35%", height: "75px" }}>
        {editMode ? (
          <MuiInput
            multiline
            rows={6}
            value={description}
            onChange={e => setDescription(e.target.value)}
            placeholder={`Description`}
            size="small"
          />
        ) : (
          <span dangerouslySetInnerHTML={{ __html: description ?? "" }} />
        )}
      </TableCell>
      <TableCell sx={{ width: "35%", height: "75px" }}>
        {editMode ? (
          <MuiInput
            value={unit}
            onChange={e => setUnit(e.target.value)}
            size="small"
            placeholder={`Unit`}
          />
        ) : (
          <span>{unit}</span>
        )}
      </TableCell>
      <TableCell sx={{ width: "15%", height: "60px" }}>
        {editMode ? (
          <div className="flex flex-row gap-4 items-end justify-end">
            <Button
              variant="contained"
              color="primary"
              size="small"
              disabled={disabled}
              onClick={() =>
                onSave({
                  Name: `${name}`,
                  Unit: unit,
                  Description: description,
                })
              }>
              <FormattedMessage
                id="APP.SAVE"
                defaultMessage="Save"
                description="General save text"
              />
            </Button>
            <Button
              variant="outlined"
              color="warning"
              size="small"
              onClick={onCancel}>
              <FormattedMessage
                id="APP.CANCEL"
                defaultMessage="Cancel"
                description="General cancel text"
              />
            </Button>
          </div>
        ) : (
          <div className="flex flex-row gap-4 items-end justify-end">
            <Edit
              className="text-xl text-darkGray cursor-pointer"
              onClick={() => onEdit(name)}
            />
          </div>
        )}
      </TableCell>
    </TableRow>
  );
}
