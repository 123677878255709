import { createApiOperation } from "../api-client/create-api-operation";

export const Synchronization = {
  downloadJSON: createApiOperation<never, "/Api/Synchronization", "get">(
    "/Api/Synchronization",
    "get",
    { file: true },
  ),
  uploadJSON: createApiOperation<never, "/Api/Synchronization", "post">(
    "/Api/Synchronization",
    "post",
  ),
  isProduction: createApiOperation<
    boolean,
    "/Api/Synchronization/IsProduction",
    "get"
  >("/Api/Synchronization/IsProduction", "get"),
};
