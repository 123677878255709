export type VariableInfo = {
  section: string;
  variable: string;
};

const splitByDot = (str: string): VariableInfo => {
  const [section, ...rest] = str.split(".");
  const variable = rest.join(".");
  return { section, variable };
};

export function mapStringToVariableInfo(items: string[]): VariableInfo[] {
  return (items || []).map(splitByDot);
}
