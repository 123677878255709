import { VariableRenderProps } from "../../../../../types/variable-render-props";

export function renderForP1ToP4({ methods }: VariableRenderProps) {
  const applicableCode = ["P1", "P2", "P3", "P4"];
  const baseCode = methods?.watch("Code.Base");
  const currentCode = methods?.watch("Code.Current");
  return (
    applicableCode.includes(baseCode) ||
    (applicableCode.includes(currentCode) && !baseCode)
  );
}
