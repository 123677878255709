import React, { useEffect } from "react";
import { FormProvider, useForm } from "react-hook-form";

import { yupResolver } from "@hookform/resolvers/yup";

import { Input } from "../../../../api/types/inputs";
import { useApiValidation } from "../../../../hooks/useApiValidation";
import { useAuth } from "../../../../hooks/useAuth";
import { ApiValidationErrors } from "../../../../types/api-error";
import { CafsDatasetAssign } from "../../components/CafsDatasetAssign";
import { CafsInfo } from "../../components/CafsInfo";
import { defaultInputFormValues } from "../../utils/default-input-form-values";
import { trimInputData } from "../../utils/trim-input-data";

import { inputFormSchema } from "./input-form-schema";
import { InputForm } from "./InputForm";

export type InputDetailsFormProps = {
  data: Input;
  onSubmit: (data: Input) => void;
  apiValidationErrors: ApiValidationErrors;
};

export function InputDetails({
  data,
  onSubmit,
  apiValidationErrors,
}: InputDetailsFormProps) {
  const methods = useForm({
    resolver: yupResolver(inputFormSchema),
    mode: "onChange",
  });

  const { isFarmTreeRole } = useAuth();

  useEffect(() => {
    methods.reset(defaultInputFormValues(data));
    methods.trigger();
  }, [data]);

  const handleSubmit = (data: Record<string, { Base: string }>) => {
    const transformedData = trimInputData(data);
    onSubmit(transformedData);
  };

  useApiValidation(apiValidationErrors, methods);

  return (
    <>
      <CafsInfo data={data} formState={methods.formState} />

      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(handleSubmit)}>
          <InputForm data={data} />
        </form>
      </FormProvider>

      {isFarmTreeRole && !!data?.EnglishName?.Current?.length && (
        <CafsDatasetAssign cafsDatasets={data.AssignedDatasets} />
      )}
    </>
  );
}
