import { useEffect } from "react";
import { FieldValues, FormProvider, useForm } from "react-hook-form";

import { yupResolver } from "@hookform/resolvers/yup";
import { Alert } from "@mui/material";

import { PlantGrowth } from "../../../../../api/types/plants";
import { useApiValidation } from "../../../../../hooks/useApiValidation";
import { useFetchData } from "../../../../../hooks/useFetchData";
import { SectionProps } from "../../../../../types/section-props";
import { useGrowthData } from "../../../api/useGrowthData";
import { useGrowthUpdate } from "../../../api/useGrowthUpdate";
import { CafsSection } from "../../../const/cafs-section";
import { useSectionSaveDisabled } from "../../../hooks/useSectionSaveDisabled";
import { mapInitialValues } from "../../../utils/map-initial-values";
import { renderCafsTableRow } from "../../../utils/render-cafs-table-row";
import { CafsTable } from "../../cafs-table/CafsTable";
import { SectionRemarks } from "../SectionRemarks";

import { VegetationObserver } from "./utils/vegetation-observer";
import { growthSchema } from "./growth-schema";
import { useGrowthVariables } from "./useGrowthVariables";

export function GrowthSection({ expanded }: SectionProps) {
  const variables = useGrowthVariables();

  const { shouldFetchData } = useFetchData(expanded);
  const { growthData, isPending } = useGrowthData(shouldFetchData);

  const { updateGrowth, apiErrors } = useGrowthUpdate({
    onSuccess: () => {},
  });

  const methods = useForm<FieldValues>({
    resolver: yupResolver(growthSchema),
    mode: "onChange",
  });

  const { disabled } = useSectionSaveDisabled(methods, isPending);

  useEffect(() => {
    if (growthData) {
      methods.reset(mapInitialValues(growthData, variables));
    }
  }, [growthData]);

  const handleSubmit = (data: FieldValues) => {
    updateGrowth(data);
  };

  useApiValidation(apiErrors, methods);

  return growthData && !isPending ? (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(handleSubmit)}>
        <SectionRemarks<PlantGrowth> name="GrowthRemarks" data={growthData} />
        <CafsTable<PlantGrowth>
          section={CafsSection.PlantGrowth}
          rows={variables}
          render={renderCafsTableRow}
          data={growthData ?? {}}
          saveDisabled={disabled}
        />
        <VegetationObserver />
      </form>
    </FormProvider>
  ) : (
    <div className="w-full">
      <Alert severity="info">Loading data</Alert>
    </div>
  );
}
