import { twMerge } from "tailwind-merge";

import { PlantProduct } from "../../../../../api/types/plants";
import { Trash } from "../../../../../components/icons/trash";

export function renderTrash(
  product: PlantProduct,
  deleteFn: (id: PlantProduct) => void,
  isReadMode: boolean | undefined,
) {
  return product.CanDelete ? (
    <div
      className={twMerge(
        "flex items-center justify-center mr-2",
        isReadMode && "pointer-events-none",
      )}
      onClick={e => {
        e.preventDefault();
        e.stopPropagation();
        deleteFn(product);
      }}>
      <Trash
        className={twMerge("text-darkGray text-xl", isReadMode && "text-grey")}
      />
    </div>
  ) : null;
}
