import { useContext } from "react";
import { useFormContext } from "react-hook-form";

import { Table, TableBody, TableContainer } from "@mui/material";

import { CafsValue } from "../../../../api/types/cafs";
import { CafsVariable } from "../../../../types/cafs-variable";
import { useTooltips } from "../../api/useTooltips";
import { CafsContext } from "../../CafsContext";
import { CafsSection } from "../../const/cafs-section";
import { RenderCafsTableRowFn } from "../../types/render-cafs-table-row-fn";

import { CafsTableHeader } from "./CafsTableHeader";
import { SaveBtn } from "./SaveBtn";

type CafsTableProps<Section> = {
  section: CafsSection;
  data: Partial<Record<keyof Section, CafsValue>>;
  rows: CafsVariable<Section>[];
  render: RenderCafsTableRowFn<Section>;
  saveDisabled: boolean;
};

export function CafsTable<SectionName>({
  rows,
  render,
  data,
  section,
  saveDisabled,
}: CafsTableProps<SectionName>) {
  const methods = useFormContext();
  const { cafs, isReadMode } = useContext(CafsContext);
  const { tooltips } = useTooltips(data, section);

  return (
    <>
      <TableContainer>
        <Table data-testid={`${section}_table`}>
          <CafsTableHeader />
          <TableBody>
            {(rows || []).map(row =>
              row.shouldRender &&
              !row.shouldRender({ methods: methods, cafsType: cafs?.Type })
                ? null
                : render({
                    row: row,
                    data: data,
                    section: section,
                    tooltips: tooltips,
                    isReadMode: isReadMode,
                  }),
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <div className="mt-8">
        <SaveBtn data-testid="save_section_btn" disabled={saveDisabled} />
      </div>
    </>
  );
}
