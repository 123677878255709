import { PlantLabour } from "../../../../../api/types/plants";
import { CafsVariable } from "../../../../../types/cafs-variable";
import { CafsVariableType } from "../../../const/cafs-variable-type";

export function useLaboursVariables(): CafsVariable<PlantLabour>[] {
  return [
    {
      name: "LabourRemarks",
      type: CafsVariableType.Remarks,
    },
    {
      name: "LabourEstDayPHa",
      type: CafsVariableType.Input,
    },
    {
      name: "LabourEstFemale",
      type: CafsVariableType.Input,
    },
    {
      name: "LabourMaintDayPHaPY",
      type: CafsVariableType.Input,
    },
    {
      name: "LabourMaintFemale",
      type: CafsVariableType.Input,
    },
  ];
}
