import {
  NumberCafsValue,
  StringCafsValue,
} from "../../../../../../api/types/cafs";
import { PlantBiomassAllocation } from "../../../../../../api/types/plants";

export const potentialMenaAnnualIncrementStem = (
  values: Record<keyof PlantBiomassAllocation, StringCafsValue>,
  data: Partial<Record<keyof PlantBiomassAllocation, NumberCafsValue>>,
): number => {
  const biomassPotProdDM =
    (values.BiomassPotProdDM?.Base !== ""
      ? Number(values.BiomassPotProdDM?.Base)
      : undefined) ??
    Number(values.BiomassPotProdDM?.Current) ??
    0;

  const p3Alloc =
    (values.P3Alloc?.Base !== "" ? Number(values.P3Alloc?.Base) : undefined) ??
    Number(values.P3Alloc?.Current) ??
    0;

  const densitySapWoodKgPM3 =
    data.DensitySapWoodKgPM3?.Base ?? data.DensitySapWoodKgPM3?.Current ?? 0;

  const densityHeartWoodKgPM3 =
    data.DensityHeartWoodKgPM3?.Base ??
    data.DensityHeartWoodKgPM3?.Current ??
    0;

  const heartwoodMaxVol =
    data.HeartwoodMaxVol?.Base ?? data.HeartwoodMaxVol?.Current ?? 0;

  const economicLifeCycle =
    data.EconomicLifeCycle?.Base ?? data.EconomicLifeCycle?.Current ?? 0;

  const treeCrownDiameterNaturalM =
    data.TreeCrownDiameterNaturalM?.Base ??
    data.TreeCrownDiameterNaturalM?.Current ??
    0;

  const canopyMaxGrowthPYearM =
    data.CanopyMaxGrowthPYearM?.Base ??
    data.CanopyMaxGrowthPYearM?.Current ??
    0;

  return (
    ((((biomassPotProdDM ?? 0) * (p3Alloc ?? 0)) /
      (((densitySapWoodKgPM3 ?? 0) * (1 - (heartwoodMaxVol ?? 0)) +
        (densityHeartWoodKgPM3 ?? 0) * (heartwoodMaxVol ?? 0)) /
        1000)) *
      ((economicLifeCycle ?? 0) / 12 -
        (treeCrownDiameterNaturalM ?? 0) / (canopyMaxGrowthPYearM ?? 0) / 2)) /
    ((economicLifeCycle ?? 0) / 12)
  );
};
