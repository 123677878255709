import { FormattedMessage } from "react-intl";

import { Hashtag } from "../../../components/icons/Hashtag";

export type IdProps = {
  id: number | null | undefined;
};

export function IdInfo({ id }: IdProps) {
  return (
    <div className="flex flex-col text-darkGray h-[70px]">
      <div className="flex flex-row gap-2 mb-2 items-start flex-1">
        <Hashtag className="text-2xl text-primary" />
        <span className="font-semibold">
          <FormattedMessage
            id="PLANT_VIEW.IDL"
            defaultMessage="Id"
            description="Id label on plant page view"
          />
        </span>
      </div>
      <div className="text-nowrap">{id}</div>
    </div>
  );
}
