import { useQuery } from "@tanstack/react-query";

import { Tooltips } from "../../../api/tooltips";
import { QueryKey } from "../../../consts/query-keys";

export function useTooltips() {
  const queryFn = () =>
    Tooltips.list({ query: { filter: "Translations", all: true } });

  const { data: tooltips, isPending } = useQuery({
    queryKey: [QueryKey.PlantTooltips],
    queryFn: queryFn,
    staleTime: Infinity,
  });

  return {
    tooltips,
    isPending,
  };
}
