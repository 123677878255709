import { createContext, PropsWithChildren } from "react";

import { Cafs } from "../../api/types/cafs";
import { ReferencesTo } from "../../api/types/referencesTo";
import { Access } from "../../consts/access";
import { Status } from "../../consts/status";

export type CafsContextProps = {
  cafs: Cafs;
  assignedReferences: ReferencesTo | undefined;
  isReadMode: boolean | undefined;
  isDraftNew: boolean | undefined;
  isDraftCopy: boolean | undefined;
};

export const CafsContext = createContext<CafsContextProps>({
  cafs: {},
  isReadMode: undefined,
  isDraftNew: undefined,
  isDraftCopy: undefined,
  assignedReferences: undefined,
});

export type CafsContextProviderProps = PropsWithChildren & {
  cafs: Cafs;
  assignedReferences: ReferencesTo | undefined;
};

export function CafsContextProvider({
  cafs,
  assignedReferences,
  children,
}: CafsContextProviderProps) {
  const isReadMode = cafs?.AccessType === Access.Read;
  const isDraftNew = cafs?.Status === Status.DraftNew;
  const isDraftCopy = cafs?.Status === Status.DraftEdit;

  return (
    <CafsContext.Provider
      value={{
        cafs,
        assignedReferences,
        isDraftCopy,
        isReadMode,
        isDraftNew,
      }}>
      {children}
    </CafsContext.Provider>
  );
}
