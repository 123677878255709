import { PropsWithChildren, SyntheticEvent } from "react";

import { AccordionDetails, Badge, Tooltip } from "@mui/material";

import { StyledAccordion } from "../../../components/StyledAccordion";
import { StyledAccordionSummary } from "../../../components/StyledAccordionSummary";
import { CafsSection } from "../const/cafs-section";

export type PlantVariableItemProps = PropsWithChildren & {
  onChange: (event: SyntheticEvent<Element, Event>, expanded: boolean) => void;
  expanded: boolean;
  name: string;
  section?: CafsSection;
  highlighted?: boolean;
  variables?: string[];
};

export function VariableSection({
  onChange,
  expanded,
  children,
  section,
  highlighted,
  variables,
  name,
  ...props
}: PlantVariableItemProps) {
  return (
    <StyledAccordion
      onChange={onChange}
      expanded={expanded}
      highlighted={highlighted}
      {...props}>
      <StyledAccordionSummary expanded={expanded}>
        {expanded ? (
          <Tooltip placement="right" title={variables?.join(", ")}>
            <Badge
              badgeContent={variables?.length}
              color="primary"
              sx={{
                ".MuiBadge-badge": {
                  top: "-2px",
                  right: "-8px",
                },
              }}>
              <h2 className="text-3xl font-semibold">{name}</h2>
            </Badge>
          </Tooltip>
        ) : (
          <div className="flex flex-row gap-2 items-center justify-center">
            <Tooltip placement="top-start" title={variables?.join(", ")}>
              <Badge
                badgeContent={variables?.length}
                color="secondary"
                sx={{
                  ".MuiBadge-badge": {
                    right: "-10px",
                  },
                }}>
                <h2>{name}</h2>
              </Badge>
            </Tooltip>
          </div>
        )}
      </StyledAccordionSummary>
      <AccordionDetails>{children}</AccordionDetails>
    </StyledAccordion>
  );
}
