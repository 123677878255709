import { useQuery } from "@tanstack/react-query";

import { Synchronization } from "../../../api/synchronization";
import { QueryKey } from "../../../consts/query-keys";

export const useIsProduction = (enabled: boolean) => {
  const { data: isProduction } = useQuery({
    queryKey: [QueryKey.IsProduction],
    queryFn: () => Synchronization.isProduction(),
    enabled: enabled,
  });

  return { isProduction };
};
