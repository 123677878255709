import React, { useState } from "react";
import { useParams } from "react-router-dom";

import { ExpandMore } from "@mui/icons-material";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Alert,
} from "@mui/material";

import { LoadingPlaceholder } from "../../../../components/LoadingPlaceholder";
import { SearchBar } from "../../../../components/SearchBar";
import { useHistoryData } from "../../api/useHistoryData";
import { filterHistory } from "../../utils/filter-history";

import { CafsHistoryInfoRow } from "./CafsHistoryInfoRow";
import { CafsHistoryTable } from "./CafsHistoryTable";
import { CafsHistoryTitle } from "./CafsHistoryTitle";

export function CafsHistoryDrawerContent() {
  const [expanded, setExpanded] = useState<number | null | undefined>();
  const [searchTerm, setSearchTerm] = useState("");

  const { datasetId, id } = useParams();

  const { data, isLoading: loadingCafsDetails } = useHistoryData(
    datasetId!,
    id!,
  );

  const filteredAudits =
    data?.Audits?.filter(i => filterHistory(i, searchTerm)) || [];

  const handleChange =
    (panel: number) => (event: React.SyntheticEvent, isExpanded: boolean) => {
      setExpanded(isExpanded ? panel : undefined);
    };

  const handleSearchTermChange = (value: string) => {
    if (!!value.length) {
      setSearchTerm("");
      setExpanded(undefined);
    }
    setSearchTerm(value);
  };

  return loadingCafsDetails ? (
    <LoadingPlaceholder />
  ) : data?.Audits?.length ? (
    <>
      <div className="mt-4 mb-4">
        <SearchBar onChange={handleSearchTermChange} />
      </div>
      {filteredAudits.length ? (
        (filteredAudits || []).map((auditObj, index) => (
          <Accordion
            key={index}
            onChange={handleChange(index)}
            expanded={expanded === index}
            sx={{
              ...(expanded === index && {
                borderTop: "3px solid",
                borderColor: "primary.main",
              }),
            }}>
            <AccordionSummary expandIcon={<ExpandMore />}>
              <CafsHistoryTitle data={auditObj} expanded={expanded === index} />
            </AccordionSummary>
            <AccordionDetails>
              {auditObj?.AuditItems?.map((ai, index) => (
                <div key={index} className="mb-12">
                  <CafsHistoryInfoRow data={ai} />
                  <CafsHistoryTable data={ai} searchTerm={searchTerm} />
                </div>
              ))}
            </AccordionDetails>
          </Accordion>
        ))
      ) : (
        <Alert severity="info">No matching results...</Alert>
      )}
    </>
  ) : (
    <Alert severity="info">Empty history</Alert>
  );
}
