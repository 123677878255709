import React, { useContext, useEffect } from "react";
import { ErrorBoundary } from "react-error-boundary";
import { FormattedMessage, useIntl } from "react-intl";
import { useSearchParams } from "react-router-dom";

import { Alert, Badge, Tooltip } from "@mui/material";

import { MuiTabPanel } from "../../../components/mui/MuiTabPanel";
import { SearchBar } from "../../../components/SearchBar";
import { StyledTabItem } from "../../../components/StyledTabItem";
import { StyledTabs } from "../../../components/StyledTabs";
import { useAuth } from "../../../hooks/useAuth";
import { useDebouncedSearch } from "../../../hooks/useDebouncedSearch";
import { useTabs } from "../../../hooks/useTabs";
import { groupVariablesBySection } from "../../../utils/group-variables-by-secion";
import { mapStringToVariableInfo } from "../../../utils/map-string-to-variable-Info";
import { useSearchVariables } from "../api/useSearchVariables";
import { CafsContext } from "../CafsContext";
import { GuestsList } from "../components/cafs-sections/cafs-guests/GuestsList";
import { InputVariables } from "../components/input-variables/InputVariables";
import { ReferencesExport } from "../components/ReferencesExport";
import { CafsSection } from "../const/cafs-section";

export function InputTabs({ ...props }) {
  const { cafs } = useContext(CafsContext);
  const { tabIndex, handleTabChange } = useTabs();
  const { isFarmTreeRole } = useAuth();
  const { refetch, variablesInfo } = useSearchVariables();
  const [urlSearchParams] = useSearchParams();
  const [searchParamValue, setSearchParamValue] = useDebouncedSearch(
    "search",
    300,
  );

  const mappedVariablesInfo = mapStringToVariableInfo(variablesInfo ?? []);
  const sectionsMap = groupVariablesBySection(mappedVariablesInfo);

  const plantSections = [
    CafsSection.InputDefinition,
    CafsSection.InputCost,
    CafsSection.InputLabour,
  ];

  const inputItems = plantSections
    .map(section => sectionsMap.get(section) || [])
    .reduce((acc, sectionItems) => acc.concat(sectionItems), [])
    .filter((item, index, self) => self.indexOf(item) === index);

  useEffect(() => {
    refetch();
  }, [urlSearchParams]);

  const { formatMessage } = useIntl();

  return (
    !!cafs?.EnglishName?.Current?.length && (
      <div className="p-4 border-[1px] border-solid border-gray-200 rounded mt-4 bg-gray-50">
        <div className="mb-6">
          <SearchBar
            initialValue={searchParamValue ?? ""}
            onChange={value => setSearchParamValue(value)}
            searchIcon
            placeholder="Search for CAFS variables"
          />
        </div>
        <div className="border-[1px] border-gray w-full">
          <StyledTabs
            value={tabIndex}
            onChange={handleTabChange}
            aria-label="input tabs">
            <StyledTabItem
              selected={tabIndex === 0}
              label={
                <Tooltip placement="top-start" title={inputItems?.join(", ")}>
                  <Badge
                    badgeContent={inputItems.length}
                    color="secondary"
                    sx={{
                      ".MuiBadge-badge": {
                        right: "-14px",
                      },
                    }}>
                    {formatMessage({
                      id: "CAFS.INPUT_TAB_HEADER",
                      defaultMessage: "Input",
                      description: "Input tab header on cafs view",
                    })}
                  </Badge>
                </Tooltip>
              }
              index={0}
            />
            <StyledTabItem
              selected={tabIndex === 1}
              label={
                <Tooltip
                  placement="top-start"
                  title={sectionsMap.get("Guest")?.join(", ")}>
                  <Badge
                    badgeContent={sectionsMap.get("Guest")?.length}
                    sx={{
                      ".MuiBadge-badge": {
                        right: "-14px",
                      },
                    }}
                    color="secondary">
                    {formatMessage({
                      id: "CAFS.GUESTS_TAB_HEADER",
                      defaultMessage: "Guests",
                      description: "Guests tab header on cafs view",
                    })}
                  </Badge>
                </Tooltip>
              }
              index={1}
            />
          </StyledTabs>
        </div>

        <MuiTabPanel index={0} value={tabIndex}>
          <ErrorBoundary
            fallback={
              <Alert severity="error">
                <FormattedMessage
                  id="ERROR.FETCHING_CAFS_DATA"
                  defaultMessage="Something went wrong when fetching cafs data..."
                  description="Error message when fetching cafs data problem"
                />
              </Alert>
            }>
            <InputVariables variables={sectionsMap} />
          </ErrorBoundary>
        </MuiTabPanel>
        <MuiTabPanel index={1} value={tabIndex}>
          <ErrorBoundary
            fallback={
              <Alert severity="error">
                <FormattedMessage
                  id="ERROR.FETCHING_GUESTS_DATA"
                  defaultMessage="Something went wrong when fetching guests data..."
                  description="Error message when fetching guests data problem"
                />
              </Alert>
            }>
            <GuestsList variables={sectionsMap.get("Guest")} />
          </ErrorBoundary>
        </MuiTabPanel>
        {isFarmTreeRole && <ReferencesExport />}
      </div>
    )
  );
}
