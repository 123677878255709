import { useContext } from "react";
import * as React from "react";
import { FieldValues, Path, useFormContext } from "react-hook-form";

import { Help } from "@mui/icons-material";
import { IconButton, TableCell, TableRow, Tooltip } from "@mui/material";
import clsx from "clsx";

import { CafsValue, NumberCafsValue } from "../../../../api/types/cafs";
import { TooltipItem } from "../../../../api/types/tooltips";
import { Formula } from "../../../../components/formula/Formula";
import { RhfFormField } from "../../../../components/rhf/RhfFormField";
import { RhfYesNoRadioGroup } from "../../../../components/rhf/RhfYesNoRadioGroup";
import { TextWithEllipsis } from "../../../../components/TextWithEllipsis";
import { DataFormat } from "../../../../consts/data-format";
import { Status } from "../../../../consts/status";
import {
  getBaseValue,
  getCurrentValue,
  getParentValue,
} from "../../../../utils/variable-helper";
import { CafsContext } from "../../CafsContext";
import { CafsSection } from "../../const/cafs-section";
import { ReferenceAssign } from "../ReferenceAssign";

export type InputRowProps<T extends Path<FieldValues>> = {
  variableName: T;
  variableValue: CafsValue;
  sectionName: CafsSection;
  productCode?: string;
  guestName?: string | null;
  data?: unknown;
  tooltip?: TooltipItem;
  disabled?: boolean;
  hidden?: ["Parent" | "New Base" | "Calculated" | "Current" | ""];
};

export function BooleanRow<T extends Path<FieldValues>>({
  variableName,
  variableValue,
  sectionName,
  productCode,
  guestName,
  tooltip,
  hidden = [""],
}: InputRowProps<T>) {
  const { cafs } = useContext(CafsContext);
  const { getFieldState } = useFormContext<Record<string, NumberCafsValue>>();

  const renderCalculated = !hidden.includes("Calculated");

  const isDirty = getFieldState(`${variableName}.Base`);

  const displayEditLabel =
    getBaseValue(variableValue) !== null &&
    getBaseValue(variableValue) !== undefined &&
    cafs?.Status === Status.DraftEdit;

  const className = clsx({
    draftRow:
      isDirty.isDirty ||
      (getBaseValue(variableValue) !== null &&
        getBaseValue(variableValue) !== undefined &&
        cafs?.Status === Status.DraftEdit),
  });

  return (
    <TableRow
      sx={{ height: "85px" }}
      id={`${sectionName}_${variableName}`}
      data-testid={`VariableRow.${variableName}`}
      className={className}>
      {/* Name */}

      <TableCell>
        <div className="flex flex-col">
          <p className="font-mono">{variableName as string}</p>
          {displayEditLabel && (
            <small className="text-orange-400 italic">Edited</small>
          )}
        </div>
      </TableCell>

      {/* Tooltip */}

      <TableCell>
        {tooltip && tooltip.Description && (
          <Tooltip title={tooltip.Description}>
            <IconButton className="hover:text-primary">
              <Help />
            </IconButton>
          </Tooltip>
        )}
      </TableCell>

      <TableCell>
        <TextWithEllipsis text={tooltip?.Unit ?? ""} limit={10} />
      </TableCell>

      {/* Parent */}

      <TableCell>
        <Formula
          format={DataFormat.Boolean}
          value={getParentValue(variableValue) ?? ""}
        />
      </TableCell>

      {/* Base */}

      <TableCell>
        <RhfFormField name={`${variableName}`}>
          <RhfYesNoRadioGroup
            name={`${variableName}.Base`}
            defaultValue={false}
            dataTestId={`${variableName}.base`}
          />
        </RhfFormField>
      </TableCell>

      {/* Calculated */}

      {renderCalculated && <TableCell />}

      {/* Current */}

      <TableCell>
        <div className="text-right">
          <Formula
            format={DataFormat.Boolean}
            value={getCurrentValue(variableValue)}
          />
        </div>
      </TableCell>
      <TableCell>
        <ReferenceAssign
          fieldName={variableName}
          sectionName={sectionName}
          productCode={productCode}
          guestName={guestName}
        />
      </TableCell>
    </TableRow>
  );
}
