export enum QueryKey {
  "CafsDetails" = "CafsDetails",
  "PlantAudit" = "PlantAudit",
  "GrowthSection" = "GrowthSection",
  "TreeHabit" = "TreeHabit",
  "TreeShapes" = "TreeShapes",
  "VegetationLayers" = "VegetationLayers",
  "DataSetsCollection" = "DataSetsCollection",
  "DataSetDetails" = "DataSetDetails",
  "LifeCycleSection" = "LifeCycleSection",
  "WoodDensitySection" = "WoodDensitySection",
  "BiomassAllocationSection" = "BiomassAllocationSection",
  "Biodiversity" = "Biodiversity",
  "RootsSection" = "RootsSection",
  "RootTypes" = "RootTypes",
  "ConstraintsSection" = "ConstraintsSection",
  "GrowthConditionsSection" = "GrowthConditionsSection",
  "AgroEcologicalZoneTemperatures" = "AgroEcologicalZoneTemperatures",
  "CostsSection" = "CostsSection",
  "LabourSection" = "LabourSection",
  "CafsVariables" = "CafsVariables",
  "PlantProducts" = "PlantProducts",
  "PlantGuests" = "PlantGuests",
  "InputGuests" = "InputGuests",
  "PlantRtp" = "PlantRtp",
  "GuestRtp" = "GuestRtp",
  "GuestResponseToPressure" = "GuestResponseToPressure",
  "VariableCategories" = "VariableCategories",
  "RegionPrices" = "RegionPrices",
  "Regions" = "Regions",
  "References" = "References",
  "AllReferencesList" = "AllReferencesList",
  "ReferencesTo" = "ReferencesTo",
  "AssignedReferencesList" = "AssignedReferencesList",
  "Products" = "Products",
  "Variables" = "Variables",
  "DefinitionSection" = "DefinitionSection",
  "InputCostSection" = "InputCostSection",
  "InputLabourSection" = "InputLabourSection",
  "Tooltips" = "Tooltips",
  "PlantTooltips" = "PlantTooltips",
  "IsProduction" = "",
}
