import { useContext } from "react";
import { useFormContext } from "react-hook-form";

import { Table, TableBody, TableContainer } from "@mui/material";

import { CafsValue } from "../../../../api/types/cafs";
import { PlantGuest } from "../../../../api/types/plants";
import { CafsVariable } from "../../../../types/cafs-variable";
import { useTooltips } from "../../api/useTooltips";
import { CafsContext } from "../../CafsContext";
import { CafsSection } from "../../const/cafs-section";
import { RenderGuestTableRowFn } from "../../types/render-guest-table-row-fn";
import { SaveBtn } from "../cafs-table/SaveBtn";

import { GuestTableHeader } from "./GuestTableHeader";

type ProductTableProps = {
  data: Partial<Record<keyof PlantGuest, CafsValue>>;
  rows: CafsVariable<PlantGuest>[];
  render: RenderGuestTableRowFn<PlantGuest>;
  saveDisabled: boolean;
  guestName: string | null | undefined;
};

export function GuestTable({
  rows,
  render,
  data,
  saveDisabled,
  guestName,
}: ProductTableProps) {
  const methods = useFormContext();
  const { cafs, isReadMode } = useContext(CafsContext);
  const { tooltips } = useTooltips(data, CafsSection.PlantProduct);

  return (
    <>
      <TableContainer>
        <Table data-testid={`${CafsSection.PlantProduct}_table`}>
          <GuestTableHeader />
          <TableBody>
            {(rows || []).map(row =>
              row.shouldRender &&
              !row.shouldRender({ methods: methods, cafsType: cafs?.Type })
                ? null
                : render({
                    row: row,
                    data: data,
                    section: CafsSection.PlantProduct,
                    tooltips: tooltips,
                    isReadMode: isReadMode,
                    guestName: guestName,
                  }),
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <div className="mt-8">
        <SaveBtn data-testid="save_section_btn" disabled={saveDisabled} />
      </div>
    </>
  );
}
