import { PropsWithChildren } from "react";

import { UserRole } from "../api/types/user";

type RenderByRoleProps = PropsWithChildren & {
  role: UserRole | undefined;
  allowedRoles: UserRole[];
};

export function RenderByRole({
  role,
  allowedRoles,
  children,
}: RenderByRoleProps) {
  if (!role) {
    return null;
  }

  return allowedRoles.includes(role) ? <>{children}</> : null;
}
