import { useContext } from "react";

import { Add } from "@mui/icons-material";
import { Alert, Button } from "@mui/material";

import { PlantResponseToPressure } from "../../../../../api/types/plants";
import { useAccordion } from "../../../../../hooks/useAccordion";
import { useModal } from "../../../../../hooks/useModal";
import { CafsContext } from "../../../CafsContext";
import { CreatePressureModal } from "../../../modals/CreatePressureModal";

import { PressureEdit } from "./PressureEdit";
import { RtpItem } from "./PressuresListItem";

export type PlantRtpListProps = {
  data: PlantResponseToPressure[];
};
export function PressuresList({ data }: PlantRtpListProps) {
  const { isReadMode } = useContext(CafsContext);
  const { expanded, handleChange: toggleAccordion } = useAccordion();

  const { open, handleOpen, handleClose } = useModal();

  function openCreateRtpModal() {
    handleOpen();
  }

  return (
    <>
      {!data.length && (
        <Alert severity="info">
          There is no responses to pressure for this plant.
        </Alert>
      )}
      {data.map(rtp => (
        <RtpItem
          key={rtp.Id}
          item={rtp}
          onChange={toggleAccordion(rtp.Id!)}
          expanded={expanded === rtp.Id}>
          <PressureEdit data={rtp} isReadMode={isReadMode} />
        </RtpItem>
      ))}
      <div className="mt-6 border-t-[1px] border-grey pt-6">
        <Button
          variant="outlined"
          color="primary"
          startIcon={<Add />}
          disabled={isReadMode}
          onClick={() => openCreateRtpModal()}>
          Add new
        </Button>
      </div>
      <CreatePressureModal open={open} onClose={handleClose} />
    </>
  );
}
