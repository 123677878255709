import React, { useMemo, useState } from "react";
import { FormattedMessage } from "react-intl";

import { Autocomplete, TextField } from "@mui/material";

import { CategoryMap } from "../api/useFetchTooltips";

import { VariablesTable } from "./VariablesTable";

export type PlantTabContentProps = {
  data: CategoryMap;
};

export function TooltipTabContent({ data }: PlantTabContentProps) {
  const options = useMemo(() => Object.keys(data || {}), [data]);
  const [selectedCategory, setSelectedCategory] = useState<string>(options[0]);

  return (
    <div className="mt-4">
      <div className="w-full">
        <h2 className="text-2xl font-bold">
          <FormattedMessage
            id="TOOLTIP_SETTINGS.SELECT_SECTION"
            description="Section selection section title on dashboard page"
            defaultMessage="Select section"
          />
        </h2>
        {options.length > 1 && (
          <div className="mt-4 h-full">
            <Autocomplete
              size="small"
              value={selectedCategory}
              onChange={(e, value) => setSelectedCategory(value ?? "")}
              options={options}
              renderInput={params => <TextField {...params} label="Section" />}
            />
          </div>
        )}
        <div className="mt-12">
          <VariablesTable
            data={data[selectedCategory as keyof typeof data]}
            category={selectedCategory}
          />
        </div>
      </div>
    </div>
  );
}
