import { GuestResponseToPressures } from "../../../../../api/types/guests";
import { PlantGuest } from "../../../../../api/types/plants";
import { createOptions } from "../../../../../utils/create-options";

import { GuestPressuresList } from "./GuestPressuresList";

export type GuestResponseToPressureTableProps = {
  data: GuestResponseToPressures | undefined;
  guest: PlantGuest;
};

export function GuestPressures({
  data,
  guest,
}: GuestResponseToPressureTableProps) {
  const categories = createOptions(data?.Categories);

  return (
    <div className="border-grey border-[1px] rounded p-8">
      <h6 className="text-xl mb-8 font-semibold text-primary">
        Guest response to pressure
      </h6>
      <GuestPressuresList
        data={data?.GuestResponseToPressures ?? []}
        guestId={guest.Id}
        categories={categories}
      />
    </div>
  );
}
