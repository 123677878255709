import { PropsWithChildren, SyntheticEvent, useContext } from "react";

import { AccordionDetails } from "@mui/material";
import { twMerge } from "tailwind-merge";

import { GuestResponseToPressure } from "../../../../../api/types/guests";
import { StyledAccordion } from "../../../../../components/StyledAccordion";
import { StyledAccordionSummary } from "../../../../../components/StyledAccordionSummary";
import { useModal } from "../../../../../hooks/useModal";
import { CafsContext } from "../../../CafsContext";
import { DeleteGuestPressureModal } from "../../../modals/DeleteGuestPressureModal";

import { renderGuestTrash } from "./render-guest-trash";

export type GuestRtpItemProps = PropsWithChildren & {
  item: GuestResponseToPressure;
  onChange: (event: SyntheticEvent<Element, Event>, expanded: boolean) => void;
  guestId: number | undefined;
  expanded: boolean;
};

export function GuestPressureItem({
  children,
  item,
  onChange,
  expanded,
  guestId,
}: GuestRtpItemProps) {
  const { isReadMode } = useContext(CafsContext);
  const { open, handleOpen, handleClose } = useModal();

  const accordionSummaryClass = twMerge(
    "w-full flex justify-between items-center",
    expanded && "text-2xl font-semibold mb-4 ",
  );

  return (
    <>
      <StyledAccordion onChange={onChange} expanded={expanded}>
        <StyledAccordionSummary expanded={expanded}>
          <div className={accordionSummaryClass}>
            {item.Category?.Current ?? item.Category?.Base}
          </div>
          {renderGuestTrash(item, handleOpen, isReadMode)}
        </StyledAccordionSummary>
        <AccordionDetails>{children}</AccordionDetails>
      </StyledAccordion>

      <DeleteGuestPressureModal
        open={open}
        onClose={handleClose}
        guestId={guestId}
        pressure={item}
      />
    </>
  );
}
