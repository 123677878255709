import { ErrorBoundary } from "react-error-boundary";

import { Alert } from "@mui/material";

import { useAccordion } from "../../../../hooks/useAccordion";
import { CafsSection } from "../../const/cafs-section";
import { CafsSectionsMap } from "../../const/cafs-sections-map";
import { InputCosts } from "../cafs-sections/Input-costs/InputCostSection";
import { InputDefinitionSection } from "../cafs-sections/Input-definition/InputDefinitionSection";
import { InputLabourSection } from "../cafs-sections/input-labour/InputLabourSection";
import { VariableSection } from "../VariableSection";

type PlantVariablesProps = {
  variables: Map<string, string[]>;
};

export function InputVariables({ variables }: PlantVariablesProps) {
  const { expanded, handleChange } = useAccordion();
  return (
    <>
      <VariableSection
        name={CafsSectionsMap.get(CafsSection.InputDefinition) ?? ""}
        onChange={handleChange(CafsSection.InputDefinition)}
        expanded={expanded === CafsSection.InputDefinition}
        highlighted={Array.from(variables.keys()).includes(
          CafsSection.InputDefinition,
        )}
        variables={variables.get(CafsSection.InputDefinition)}
        data-testid={`Section.${CafsSection.InputDefinition}`}>
        <ErrorBoundary
          fallback={<Alert severity="error">Something went wrong...</Alert>}>
          <InputDefinitionSection
            expanded={expanded === CafsSection.InputDefinition}
          />
        </ErrorBoundary>
      </VariableSection>

      <VariableSection
        name={CafsSectionsMap.get(CafsSection.InputCost) ?? ""}
        onChange={handleChange(CafsSection.InputCost)}
        expanded={expanded === CafsSection.InputCost}
        highlighted={Array.from(variables.keys()).includes(
          CafsSection.InputCost,
        )}
        variables={variables.get(CafsSection.InputCost)}
        data-testid={`Section.${CafsSection.InputCost}`}>
        <ErrorBoundary
          fallback={<Alert severity="error">Something went wrong...</Alert>}>
          <InputCosts expanded={expanded === CafsSection.InputCost} />
        </ErrorBoundary>
      </VariableSection>

      <VariableSection
        name={CafsSectionsMap.get(CafsSection.InputLabour) ?? ""}
        onChange={handleChange(CafsSection.InputLabour)}
        expanded={expanded === CafsSection.InputLabour}
        highlighted={Array.from(variables.keys()).includes(
          CafsSection.InputLabour,
        )}
        variables={variables.get(CafsSection.InputLabour)}
        data-testid={`Section.${CafsSection.InputLabour}`}>
        <ErrorBoundary
          fallback={<Alert severity="error">Something went wrong...</Alert>}>
          <InputLabourSection expanded={expanded === CafsSection.InputLabour} />
        </ErrorBoundary>
      </VariableSection>
    </>
  );
}
