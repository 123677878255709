import { Cafs } from "../api/types/cafs";
import { TooltipItem } from "../api/types/tooltips";
import { CafsType } from "../consts/cafsType";

export function getCorrectUnit(
  cafs: Cafs | undefined,
  tooltip: TooltipItem,
): string {
  if (!cafs || !tooltip.Unit) {
    return "";
  }

  const units = tooltip.Unit.split(",").map(unit => unit.trim());
  switch (cafs.Type) {
    case CafsType.Crop:
      return units[0] ?? "";
    case CafsType.Tree:
      return units[1] ?? "";
    default:
      return units[0] ?? "";
  }
}
