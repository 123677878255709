import { useSearchParams } from "react-router-dom";

import { useQuery } from "@tanstack/react-query";

import { Sections } from "../../../api/sections";
import { QueryKey } from "../../../consts/query-keys";

export const useSearchVariables = () => {
  const [urlSearchParams] = useSearchParams();
  const {
    data: variablesInfo,
    isPending,
    refetch,
  } = useQuery({
    queryKey: [QueryKey.CafsVariables],
    queryFn: () =>
      urlSearchParams.get("search") &&
      String(urlSearchParams.get("search")).length > 2
        ? Sections.getFields({
            query: {
              search: urlSearchParams.get("search")
                ? String(urlSearchParams.get("search"))
                : undefined,
            },
          }).then(response => {
            return response;
          })
        : Promise.resolve([]),
    throwOnError: true,
  });

  return { variablesInfo, isPending, refetch };
};
