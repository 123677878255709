import React from "react";

import { MuiTabPanel } from "../../../components/mui/MuiTabPanel";
import { StyledTabItem } from "../../../components/StyledTabItem";
import { StyledTabs } from "../../../components/StyledTabs";
import { useTabs } from "../../../hooks/useTabs";
import { CategoryMap, useFetchTooltips } from "../api/useFetchTooltips";

import { TooltipTabContent } from "./TooltipTabContent";

export function TooltipTabs() {
  const { tabIndex, handleTabChange } = useTabs();
  const { data } = useFetchTooltips();

  const plantData: CategoryMap = {};
  const inputData: CategoryMap = {};
  const guestData: CategoryMap = {};
  const plantProductData: CategoryMap = {};
  const translations: CategoryMap = {};

  Object.keys(data ?? {}).forEach(key => {
    if (key.includes("PlantProduct")) {
      plantProductData[key] = (data ?? {})[key];
    } else if (key.includes("Plant")) {
      plantData[key] = (data ?? {})[key];
    }
    if (key.includes("Input")) {
      inputData[key] = (data ?? {})[key];
    }
    if (key.includes("Guest")) {
      guestData[key] = (data ?? {})[key];
    }
    if (key.includes("Translations")) {
      translations[key] = (data ?? {})[key];
    }
  });

  return (
    <div>
      <div className="border-[1px] border-gray w-full">
        <StyledTabs
          value={tabIndex}
          onChange={handleTabChange}
          aria-label="tooltip tabs">
          <StyledTabItem selected={tabIndex === 0} label="Plant" index={0} />
          <StyledTabItem selected={tabIndex === 1} label="Input" index={1} />
          <StyledTabItem selected={tabIndex === 2} label="Guests" index={2} />
          <StyledTabItem selected={tabIndex === 3} label="Products" index={3} />
          <StyledTabItem
            selected={tabIndex === 4}
            label="Translations"
            index={4}
          />
        </StyledTabs>
      </div>
      <MuiTabPanel index={0} value={tabIndex}>
        {data && <TooltipTabContent data={plantData} />}
      </MuiTabPanel>
      <MuiTabPanel index={1} value={tabIndex}>
        {data && <TooltipTabContent data={inputData} />}
      </MuiTabPanel>
      <MuiTabPanel index={2} value={tabIndex}>
        {data && <TooltipTabContent data={guestData} />}
      </MuiTabPanel>
      <MuiTabPanel index={3} value={tabIndex}>
        <TooltipTabContent data={plantProductData} />
      </MuiTabPanel>
      <MuiTabPanel index={4} value={tabIndex}>
        <TooltipTabContent data={translations} />
      </MuiTabPanel>
    </div>
  );
}
