import React from "react";

import { TableCell, TableRow } from "@mui/material";

import { Dataset } from "../../../api/types/datasets";
import { Region } from "../../../api/types/regions";

import { EditedField } from "./DatasetsTable";
import { DatasetUpdate } from "./DatasetUpdate";
import { OwnerUpdate } from "./OwnerUpdate";
import { RegionUpdate } from "./RegionUpdate";

export type RegionsTableRowProps = {
  data: Dataset;
  onEdit: (row: Dataset, column: string) => void;
  onCancel: () => void;
  onRegionAssign: (data: { dataset: Dataset; region: Region | null }) => void;
  onSave: (data: {
    id?: number | undefined | null;
    name?: string | undefined | null;
    region?: Region | null;
    owner?: string | null;
  }) => void;
  editedField: EditedField | null | undefined;
  regions: Region[] | null | undefined;
  disabled: boolean;
};

export function DatasetsTableRow({
  data,
  onEdit,
  onCancel,
  onSave,
  onRegionAssign,
  editedField,
  regions,
}: RegionsTableRowProps) {
  return (
    <TableRow sx={{ height: "73px" }}>
      <TableCell>
        <div className="flex flex-row gap-4 items-start justify-between">
          <DatasetUpdate
            data={data}
            onEdit={onEdit}
            editMode={
              editedField?.row === data.Id && editedField?.column === "dataset"
            }
            onSave={onSave}
            onCancel={onCancel}
          />
        </div>
      </TableCell>
      <TableCell>
        <div className="flex flex-row gap-4 items-start justify-between w-full">
          <RegionUpdate
            data={data}
            onEdit={onEdit}
            onSave={onRegionAssign}
            onCancel={onCancel}
            regions={regions}
            editMode={
              editedField?.row === data.Id && editedField?.column === "region"
            }
          />
        </div>
      </TableCell>
      <TableCell>
        <div className="flex flex-row gap-4 items-start justify-between">
          <OwnerUpdate
            data={data}
            onEdit={onEdit}
            editMode={
              editedField?.row === data.Id && editedField?.column === "owner"
            }
            onSave={onSave}
            onCancel={onCancel}
          />
        </div>
      </TableCell>
    </TableRow>
  );
}
