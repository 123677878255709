import React, { useContext, useState } from "react";
import { FormattedMessage } from "react-intl";

import { Button } from "@mui/material";

import { Dataset } from "../../../api/types/datasets";
import { Region } from "../../../api/types/regions";
import { AppContext } from "../../../AppContext";
import { Edit } from "../../../components/icons/Edit";
import { Save } from "../../../components/icons/Save";
import { MuiInput } from "../../../components/mui/MuiInput";
import { RenderByRole } from "../../../components/RenderByRole";
import { UserRole } from "../../../consts/user-role";

export type DatasetNameProps = {
  data: Dataset;
  onEdit: (row: Dataset, column: string) => void;
  onSave: (data: {
    id: number | undefined | null;
    name?: string | undefined | null;
    region?: Region | null;
    owner?: string | null;
  }) => void;
  onCancel: () => void;
  editMode: boolean | undefined;
};
export function OwnerUpdate({
  data,
  onEdit,
  editMode,
  onCancel,
  onSave,
}: DatasetNameProps) {
  const [dataToSave, setDataToSave] = useState(data?.Owner);
  const { user } = useContext(AppContext);

  return editMode ? (
    <div className="flex items-center gap-2 w-full">
      <div className="flex-1">
        <MuiInput
          name={data?.Owner ?? ""}
          value={dataToSave}
          placeholder="Owner"
          onChange={e => setDataToSave(e.target.value)}
        />
      </div>
      <div className="flex flex-row gap-4 items-center justify-end flex-1">
        <Button
          variant="contained"
          color="primary"
          startIcon={<Save />}
          onClick={() =>
            onSave({ name: data.Name, owner: dataToSave, id: data.Id })
          }
          size="small">
          <FormattedMessage
            id="APP.SAVE"
            defaultMessage="Save"
            description="General save text"
          />
        </Button>
        <Button
          variant="outlined"
          color="warning"
          size="small"
          onClick={onCancel}>
          <FormattedMessage
            id="APP.CANCEL"
            defaultMessage="Cancel"
            description="General cancel text"
          />
        </Button>
      </div>
    </div>
  ) : (
    <>
      {data?.Owner || "-"}
      <RenderByRole
        role={user?.Role}
        allowedRoles={[UserRole.FarmTreeMaster, UserRole.FarmTreeDeveloper]}>
        <Edit
          className="text-xl text-darkGray hover:text-primary cursor-pointer"
          onClick={() => onEdit(data, "owner")}
        />
      </RenderByRole>
    </>
  );
}
