import { useContext } from "react";
import { FieldValues, FormProvider, useForm } from "react-hook-form";
import { FormattedMessage } from "react-intl";

import { yupResolver } from "@hookform/resolvers/yup";
import { Add } from "@mui/icons-material";
import { Alert, Button } from "@mui/material";

import { PlantGuest, PlantProduct } from "../../../../../api/types/plants";
import { useApiValidation } from "../../../../../hooks/useApiValidation";
import { ApiError } from "../../../../../types/api-error";
import { CafsForm } from "../../../../../types/cafs-form";
import { CafsContext } from "../../../CafsContext";
import { useProductCodesOptions } from "../../../hooks/useProductCodesOptions";
import { ProductsContext } from "../../../ProductsContext";
import { renderBaseRow } from "../../../utils/render-base-row";
import { CafsBaseTable } from "../../CafsBaseTable";
import { SectionRemarks } from "../SectionRemarks";

import { createProductSchema } from "./create-product-schema";
import { useProductCreateVariables } from "./useProducCreateVariables";

export type PlantProductCreateProps = {
  onSubmit: (data: Partial<CafsForm<PlantProduct>>) => void;
  customErrors: ApiError["Body"]["Errors"];
  onCancel: () => void;
  disabled?: boolean;
};

export function ProductCreate({
  onSubmit,
  customErrors,
  onCancel,
  disabled,
}: PlantProductCreateProps) {
  const { plantProducts } = useContext(ProductsContext);
  const { options: availableCodes } = useProductCodesOptions(
    plantProducts,
    undefined,
  );

  const { isReadMode } = useContext(CafsContext);

  const methods = useForm<FieldValues>({
    resolver: yupResolver(createProductSchema),
    shouldFocusError: true,
    mode: "onChange",
  });

  const variables = useProductCreateVariables();

  useApiValidation(customErrors, methods);

  return (
    <FormProvider {...methods}>
      {!!availableCodes.length ? (
        <>
          <div>
            <CafsBaseTable variables={variables} render={renderBaseRow} />
            <SectionRemarks<PlantGuest> name="GeneralRemarks" />
          </div>
          <div className="mt-12 flex flex-row gap-4">
            <Button
              type="submit"
              variant="contained"
              color="primary"
              startIcon={<Add />}
              disabled={!methods.formState.isValid || isReadMode || disabled}
              onClick={methods.handleSubmit(onSubmit)}>
              <FormattedMessage
                id="BTN.CREATE_PRODUCT"
                defaultMessage="Create"
                description="Create product button"
              />
            </Button>
            <Button
              type="button"
              variant="outlined"
              color="primary"
              onClick={onCancel}>
              <FormattedMessage id="APP.CANCEL" defaultMessage="Cancel" />
            </Button>
          </div>
        </>
      ) : (
        <Alert severity="info">
          <FormattedMessage
            id="MSG.NO_AVAILABLE_CODES"
            defaultMessage="There are no available product codes. All products are already
          assigned to this plant."
            description="Message when no available product codes to add"
          />
        </Alert>
      )}
    </FormProvider>
  );
}
