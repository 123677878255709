import React, { useEffect } from "react";
import { FormProvider, useForm } from "react-hook-form";

import { yupResolver } from "@hookform/resolvers/yup";

import { Input } from "../../../../api/types/inputs";
import { Plant } from "../../../../api/types/plants";
import { useApiValidation } from "../../../../hooks/useApiValidation";
import { useAuth } from "../../../../hooks/useAuth";
import { ApiValidationErrors } from "../../../../types/api-error";
import { CafsDatasetAssign } from "../../components/CafsDatasetAssign";
import { CafsInfo } from "../../components/CafsInfo";
import { defaultPlantFormValues } from "../../utils/default-plant-form-values";
import { trimPlantData } from "../../utils/trim-plant-data";

import { plantFormSchema } from "./plant-form-schema";
import { PlantForm } from "./PlantForm";

export type PlantDetailsFormProps = {
  data: Plant;
  onSubmit: (data: Input) => void;
  apiValidationErrors: ApiValidationErrors;
};

export function PlantDetails({
  data,
  onSubmit,
  apiValidationErrors,
}: PlantDetailsFormProps) {
  const { isFarmTreeRole } = useAuth();

  const methods = useForm({
    resolver: yupResolver(plantFormSchema),
    mode: "onChange",
  });

  useEffect(() => {
    methods.reset(defaultPlantFormValues(data));
    methods.trigger();
  }, [data]);

  const handleSubmit = (data: Record<string, { Base: string }>) => {
    const trimmedData = trimPlantData(data);
    onSubmit(trimmedData);
  };

  useApiValidation(apiValidationErrors, methods);

  return (
    <>
      <CafsInfo data={data} formState={methods.formState} />

      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(handleSubmit)}>
          <PlantForm data={data ?? {}} />
        </form>
      </FormProvider>
      {isFarmTreeRole && !!data?.EnglishName?.Current?.length && (
        <CafsDatasetAssign cafsDatasets={data.AssignedDatasets} />
      )}
    </>
  );
}
