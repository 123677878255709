import * as yup from "yup";

import { PlantConstraint } from "../../../../../api/types/plants";
import {
  CafsValueBooleanValidation,
  CafsValueNumberValidation,
  CafsValueRemarksValidation,
} from "../../../../../consts/validation";
import { CafsForm } from "../../../../../types/cafs-form";

export const biomassAllocationSchema = yup.object<
  CafsForm<keyof PlantConstraint>
>({
  PRootAlloc: CafsValueNumberValidation(true),
  PLeavesAlloc: CafsValueNumberValidation(true),
  P1Alloc: CafsValueNumberValidation(true),
  P2Alloc: CafsValueNumberValidation(true),
  P3Alloc: CafsValueNumberValidation(true),
  P4Alloc: CafsValueNumberValidation(true),
  BiomassPotProdDM: CafsValueNumberValidation(),
  PublicDatabase: CafsValueBooleanValidation(),
  ScientificSemi: CafsValueBooleanValidation(),
  Scientific: CafsValueBooleanValidation(),
  Qualitative: CafsValueBooleanValidation(),
  QuantitativeMeasured: CafsValueBooleanValidation(),
  QuantitativeProcessed: CafsValueBooleanValidation(),
  CalibratedBiomass: CafsValueBooleanValidation(),
  CalibratedHeightDBH: CafsValueBooleanValidation(),
  CalibratedTimeSeries: CafsValueBooleanValidation(),
  Validated: CafsValueBooleanValidation(),
  BiomassRemarks: CafsValueRemarksValidation(),
  PotentialMeanAnnualIncrementStem: CafsValueRemarksValidation(),
});
