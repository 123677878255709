import { InputLabour } from "../../../../../api/types/inputs";
import { CafsVariable } from "../../../../../types/cafs-variable";
import { CafsVariableType } from "../../../const/cafs-variable-type";

export function useInputlabourVariables(): CafsVariable<InputLabour>[] {
  return [
    {
      name: "LabourRemarks",
      type: CafsVariableType.Remarks,
    },
    {
      name: "LabourEstDayPHa",
      type: CafsVariableType.Input,
    },
    {
      name: "LabourEstFemaleFraction",
      type: CafsVariableType.Input,
    },
    {
      name: "LabourMaintDayPHaPY",
      type: CafsVariableType.Input,
    },
    {
      name: "LabourMaintFemaleFraction",
      type: CafsVariableType.Input,
    },
    {
      name: "IrrigationLabourDaysPerMMPerHa",
      type: CafsVariableType.Input,
    },
  ];
}
