import React, { useContext, useEffect } from "react";
import { useIntl } from "react-intl";

import { Autocomplete, TextField } from "@mui/material";

import { Dataset } from "../../../api/types/datasets";
import { useDataSets } from "../api/useDatasets";
import { DashboardContext } from "../DashboardContext";

export function DatasetSelector({ ...props }) {
  const { datasets } = useDataSets();
  const { formatMessage } = useIntl();
  const { selectedDataset, handleDatasetChange } = useContext(DashboardContext);

  const { handleDeselectAllCafs, handleTreeExpand, handleSearch } =
    useContext(DashboardContext);

  const handleDateSetChange = (value: Dataset | null) => {
    handleDatasetChange(value);
    handleTreeExpand([]);
    handleSearch("");
    handleDeselectAllCafs();
  };

  useEffect(() => {
    if (datasets?.Datasets?.length) {
      const isDatasetValid = (datasets?.Datasets).find(
        d => d.Id === selectedDataset?.Id,
      );

      if (!isDatasetValid) {
        handleDatasetChange(null);
      }
    }
  }, [datasets]);

  return (
    <div {...props} data-testid="dataset-selector">
      <div className="mt-4 h-full">
        <Autocomplete
          size="small"
          value={selectedDataset ?? null}
          onChange={(e, value) => handleDateSetChange(value)}
          options={datasets?.Datasets ?? []}
          getOptionLabel={option => option.Name ?? ""}
          renderInput={params => (
            <TextField
              {...params}
              label={formatMessage({
                id: "APP.DATASET",
                defaultMessage: "Dataset",
                description: "Dataset label",
              })}
            />
          )}
          isOptionEqualToValue={(option, value) => option.Id === value.Id}
        />
      </div>
    </div>
  );
}
